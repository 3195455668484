<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Plan & Pricing" icon="fa-solid fa-box-check" />

    <div class="generalBorderlessBox">
        <div class="row">
            <!-- <div class="col-12 bg-warning">isLogin: {{ isLogin }}</div> -->
            <!-- <div class="col-12 bg-warning" data-bs-toggle="modal" data-bs-target="#mdlPreSubscription">isLogin: {{ isLogin }} | <span class="text-danger">trigger modal CONFLICT with preference</span></div> -->

            <div class="col-12">
                <table>
                    <tr>
                        <!-- <td class="pe-2">Subscription</td> -->
                        <td>
                            <div class="btn-group">
                                <a href="#" class="btn btn-sm" :class="payOpt === 'monthly' ? 'btn-secondary text-white' : 'border'" @click="payOpt = 'monthly'">No Commitment</a>
                                <a href="#" class="btn btn-sm" :class="payOpt === 'yearly' ? 'btn-secondary text-white' : 'border'" @click="payOpt = 'yearly'">1 Year Commitment</a>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div v-if="payOpt === 'yearly'" class="col-12 pt-3 pb-2 fst-italic text-grey">
                Price shown is yearly subscription with monthly recurring charges.
            </div>
            <div v-else class="col-12">&nbsp;</div>

            <div v-if="arrPlan[0] && arrPlan[0].plan === 'FREE'" class="col-12 col-md-6 col-lg-3 d-grid">
                <div class="planBox border shadow-sm">
                    <div class="planHeader">&nbsp;</div>
                    <div class="text-center mb-1">
                        <h5>{{ func.convCamelString(arrPlan[0].plan) }}</h5>
                    </div>
                    <div class="text-center">
                        <span class="boxTitle ps-1">FOC</span>
                    </div>
                    <div class="text-center small text-grey">&nbsp</div>

                    <div class="boxButton d-grid">
                        <button v-if="getPackage === 'FREE' && isFinance === true" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Subscribed</button>
                        <!-- Non-finance user should not know what plan organization did subscribed -->
                        <button v-if="getPackage === 'FREE' && isFinance === false" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Sign Up</button>
                        <button v-if="getPackage !== 'FREE' && isLogin === true && isFinance === true" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlPreSubscription">Sign Up</button>
                        <button v-if="getPackage !== 'FREE' && isLogin === true && isFinance === false" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Sign Up</button>
                        <button v-if="getPackage !== 'FREE' && isLogin === false" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlPreSubscription">Sign Up</button>
                    </div>

                    <div class="boxContent">
                        <table class="table table-borderless table-sm">
                            <tbody>
                                <tr>
                                    <td width="25px" class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[0].userMonthlyCreditLimit }}</span> sign requests / month</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[0].userStorageLimit }}</span> storage / user</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[0].userMonthlyCreditLimit }}</span> user credit / month</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[0].memberSizeLimit }}</span> users limit</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[0].teamSizeLimit }}</span> teams limit</td>
                                </tr>
                                <!-- <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">1</span> API</td>
                                </tr> -->
                                <tr>
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[0].enableDocAndSignatureValidation === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableDocAndSignatureValidation === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Document & signature validations</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[0].enableEntityAndUserValidation === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableEntityAndUserValidation === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Entity & user validations</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[0].enableSignDocument === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableSignDocument === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Sign documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[0].enableDownload === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableDownload === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Download folder & documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[0].enableAuditTrial === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableAuditTrial === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Audit trials on folder and individual documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[0].enableTeam === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableTeam === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Work as team</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[0].enableSealFolder === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableSealFolder === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Seal folder protection</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[0].enableWorkflow === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableWorkflow === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>PDF workflow</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[0].enableReferralIncentive === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableReferralIncentive === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Referral incentives</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[0].enableSharedRack === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[0].enableSharedRack === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td><span class="fw-bold">Shared rack</span> for team storage</td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[0].dayToDeleteShreddedNotSealedFolder === 0">
                                            Non-sealed folders that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Non-sealed folders that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[0].dayToDeleteShreddedNotSealedFolder }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[0].dayToDeleteShreddedSealedFolder === 0">
                                            Sealed folders that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Sealed folders that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[0].dayToDeleteShreddedSealedFolder }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[0].dayToDeleteShreddedSealedDocument === 0">
                                            Sealed documents that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Sealed documents that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[0].dayToDeleteShreddedSealedDocument }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>

            <div v-if="arrPlan[1] && arrPlan[1].plan === 'STARTER'" class="col-12 col-md-6 col-lg-3 d-grid">
                <div class="planBoxReco shadow-sm">
                    <div class="planHeaderReco text-white">
                        Recommended
                    </div>
                    <div class="text-center mb-1">
                        <h5>{{ func.convCamelString(arrPlan[1].plan) }}</h5>
                    </div>
                    <div class="text-center mb-0 pb-0">
                        <span class="fw-bold text-uppercase">{{ arrPlan[1].currency }}</span>
                        <span class="boxTitle ps-1">{{ arrPlan[1].chargePerUser }}</span>
                        <span> / user</span>
                    </div>

                    <div v-if="payOpt === 'monthly'" class="text-center small text-grey">per month, no commitment</div>
                    <div v-if="payOpt === 'yearly'" class="text-center small text-grey">per month, 1-year commitment</div>
                        
                    <div class="boxButton d-grid">
                        <button v-if="getPackage === 'STARTER' && isFinance === true" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Subscribed</button>
                        <button v-if="getPackage === 'STARTER' && isFinance === false" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Sign Up</button>
                        <button v-if="getPackage !== 'STARTER' && isLogin === true && isFinance === true" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlConfirmSubscription" @click="preCheckout(arrPlan[1].plan, arrPlan[1].currency, arrPlan[1].chargePerUser, arrPlan[1].memberSizeLimit)">Sign Up</button>
                        <button v-if="getPackage !== 'STARTER' && isLogin === true && isFinance === false" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Sign Up</button>
                        <button v-if="getPackage !== 'STARTER' && isLogin === false" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlPreSubscription">Sign Up</button>
                    </div>
                    
                    <div class="boxContent">
                        <table class="table table-borderless table-sm">
                            <tbody>
                                <tr>
                                    <td width="25px" class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[1].userMonthlyCreditLimit }}</span> sign requests / month</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[1].userStorageLimit }}</span> storage / user</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[1].userMonthlyCreditLimit }}</span> user credit / month</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[1].memberSizeLimit }}</span> users limit</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[1].teamSizeLimit }}</span> teams limit</td>
                                </tr>

                                <!-- <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">5</span> APIs</td>
                                </tr> -->
                                <tr>
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[1].enableDocAndSignatureValidation === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableDocAndSignatureValidation === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Document & signature validations</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[1].enableEntityAndUserValidation === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableEntityAndUserValidation === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Entity & user validations</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[1].enableSignDocument === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableSignDocument === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Sign documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[1].enableDownload === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableDownload === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Download folder & documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[1].enableAuditTrial === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableAuditTrial === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Audit trials on folder and individual documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[1].enableTeam === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableTeam === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Work as team</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[1].enableSealFolder === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableSealFolder === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Seal folder protection</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[1].enableWorkflow === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableWorkflow === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>PDF workflow</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[1].enableReferralIncentive === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableReferralIncentive === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Referral incentives</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[1].enableSharedRack === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[1].enableSharedRack === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td><span class="fw-bold">Shared rack</span> for team storage</td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[1].dayToDeleteShreddedNotSealedFolder === 0">
                                            Non-sealed folders that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Non-sealed folders that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[1].dayToDeleteShreddedNotSealedFolder }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[1].dayToDeleteShreddedSealedFolder === 0">
                                            Sealed folders that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Sealed folders that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[1].dayToDeleteShreddedSealedFolder }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[1].dayToDeleteShreddedSealedDocument === 0">
                                            Sealed documents that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Sealed documents that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[1].dayToDeleteShreddedSealedDocument }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="arrPlan[2] && arrPlan[2].plan === 'STANDARD'" class="col-12 col-md-6 col-lg-3 d-grid">
                <div class="planBox border shadow-sm">
                    <div class="planHeader">
                        <span v-if="isLogin === false || isFinance === true" class="iconBlue isLink" @click="getTrial('STANDARD')">Start a trial</span>
                        <span v-else class="text-secondary">Start a trial</span>
                    </div>
                    <div class="text-center mb-1">
                        <h5>{{ func.convCamelString(arrPlan[2].plan) }}</h5>
                    </div>
                    <div class="text-center">
                        <span class="fw-bold text-uppercase">{{ arrPlan[2].currency }}</span>
                        <span class="boxTitle ps-1">{{ arrPlan[2].chargePerUser }}</span>
                        <span> / user</span>
                    </div>

                    <div v-if="payOpt === 'monthly'" class="text-center small text-grey">per month, no commitment</div>
                    <div v-if="payOpt === 'yearly'" class="text-center small text-grey">per month, 1-year commitment</div>

                    <div class="boxButton d-grid pt-0">
                        <button v-if="getPackage === 'STANDARD' && isFinance === true" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Subscribed</button>
                        <button v-if="getPackage === 'STANDARD' && isFinance === false" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Sign Up</button>

                        <button v-if="getPackage !== 'STANDARD' && isLogin === true && isFinance === true" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlConfirmSubscription" @click="preCheckout(arrPlan[2].plan, arrPlan[2].currency, arrPlan[2].chargePerUser, arrPlan[2].memberSizeLimit)">Sign Up</button>
                        <button v-if="getPackage !== 'STANDARD' && isLogin === true && isFinance === false" class="btn btn-secondary btn-lg btn-block mb-5" disabled>Sign Up</button>
                        <button v-if="getPackage !== 'STANDARD' && isLogin === false" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlPreSubscription">Sign Up</button>
                    </div>


                    <div class="boxContent">
                        <table class="table table-borderless table-sm">
                            <tbody>
                                <tr>
                                    <td width="25px" class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[2].userMonthlyCreditLimit }}</span> sign requests / month</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[2].userStorageLimit }}</span> storage / user</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[2].userMonthlyCreditLimit }}</span> user credit / month</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[2].memberSizeLimit }}</span> users limit</td>
                                </tr>
                                <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">{{ arrPlan[2].teamSizeLimit }}</span> teams limit</td>
                                </tr>
                                <!-- <tr>
                                    <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                    <td><span class="fw-bold">99</span> APIs</td>
                                </tr> -->
                                <tr>
                                    <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[2].enableDocAndSignatureValidation === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableDocAndSignatureValidation === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Document & signature validations</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[2].enableEntityAndUserValidation === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableEntityAndUserValidation === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Entity & user validations</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[2].enableSignDocument === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableSignDocument === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Sign documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[2].enableDownload === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableDownload === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Download folder & documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[2].enableAuditTrial === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableAuditTrial === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Audit trials on folder and individual documents</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[2].enableTeam === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableTeam === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Work as team</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[2].enableSealFolder === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableSealFolder === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Seal folder protection</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[2].enableWorkflow === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableWorkflow === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>PDF workflow</td>
                                </tr>
                                <tr>
                                    <td class="ps-0">
                                        <i v-if="arrPlan[2].enableReferralIncentive === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableReferralIncentive === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td>Referral incentives</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="px-0 py-0 my-0">
                                        <hr style=""/>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 align-top">
                                        <i v-if="arrPlan[2].enableSharedRack === true" class="fa-solid fa-check fa-lg iconBlue"></i>
                                        <i v-if="arrPlan[2].enableSharedRack === false" class="fa-solid fa-xmark fa-xl text-grey"></i>
                                    </td>
                                    <td><span class="fw-bold">Shared rack</span> for team storage</td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td colspan="2" class="px-0 py-0 my-0">
                                        <hr />
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[2].dayToDeleteShreddedNotSealedFolder === 0">
                                            Non-sealed folders that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Non-sealed folders that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[2].dayToDeleteShreddedNotSealedFolder }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[2].dayToDeleteShreddedSealedFolder === 0">
                                            Sealed folders that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Sealed folders that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[2].dayToDeleteShreddedSealedFolder }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="showMore === true">
                                    <td class="ps-0 align-top iconBlue"><i class="fa-solid fa-asterisk"></i></td>
                                    <td>
                                        <span v-if="arrPlan[2].dayToDeleteShreddedSealedDocument === 0">
                                            Sealed documents that have been shredded will be destruct <span class="fw-bold">immediately</span>
                                        </span>
                                        <span v-else>
                                            Sealed documents that have been shredded will be retained for <span class="fw-bold">{{ arrPlan[2].dayToDeleteShreddedSealedDocument }} days</span> before destruction
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <div class="planBox border shadow-sm">
                    <div class="planHeader">&nbsp;</div>
                    <div class="text-center mb-1">
                        <h5>Enterprise</h5>
                    </div>
                    <div class="text-center mt-4" style="padding-bottom: 16px">
                        <div class="fw-bold">Price</div>
                        <div class="fw-bold">Upon Request</div>
                    </div>
                    <div class="text-center small text-grey">&nbsp</div>

                    <div class="boxButton d-grid">
                        <button v-if="isLogin === false || isFinance === true" class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlTalk">Talk to Us</button>
                        <button v-else class="btn btn-secondary text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlTalk" disabled>Talk to Us</button>
                    </div>

                    <div class="text-center mb-3" style="line-height: 2">
                        The final price is based on customization.<br />Please reach out to discuss your specific needs.
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div v-if="showMore === false" @click="showMore = true" class="isLink text-info">Show more</div>
                <div v-else @click="showMore = false" class="isLink text-info">Show less</div>
            </div>
            <div class="col-12 mt-2 fst-italic fw-light">
                <div><i class="fa-solid fa-star-of-life fa-xs text-grey me-1"></i> FREE one-time 30 credits upon successful KYC.</div>
            </div>
        </div>
    </div>

    <!-- Modal: Register alert --> 
    <div class="modal fade" id="mdlPreSubscription" ref="mdlPreSubscription" tabindex="-1" aria-labelledby="PreSubscribtion" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Pre-Subscription</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            Please sign up on SignOn to subscribe your chosen plan, or log in if you already have an account.
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <div>
                        <button class="btn btn-secondary me-2" data-bs-dismiss="modal" @click="$router.push({ path: '/auth/login'})">
                            <i class="fa-regular fa-arrow-right-to-bracket me-2"></i>Login
                        </button>
                        <button class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="$router.push({ path: '/auth/signup'})">
                            <i class="fa-regular fa-user-plus me-2"></i>Sign Up
                        </button>
                    </div>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- Modal End-->

    <!-- Modal: TalkToUs --> 
    <div class="modal fade" id="mdlTalk" ref="mdlTalk" tabindex="-1" aria-labelledby="TalkToUs" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Talk to us</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td><input type="text" class="form-control" v-model="reqName" placeholder="Name *"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="text" class="form-control" v-model="reqEmail" placeholder="Email *"></td>
                                    </tr>
                                    <tr>
                                        <td><textarea class="form-control" v-model="reqRemark" placeholder="Request *"></textarea></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button class="btn btn-secondary me-2" data-bs-dismiss="modal" >
                        <i class="fa-solid fa-paper-plane-top me-2"></i>Submit
                    </button>
                </div>
            </div>
        </div>
    </div><!-- Modal End-->

    <!-- Modal: ConfirmSubscription --> 
    <div class="modal fade" id="mdlConfirmSubscription" ref="mdlConfirmSubscription" tabindex="-1" aria-labelledby="ConfirmSubscription" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Confirmation of Subscription</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div v-if="payOpt === 'yearly'" class="col-12 mb-3">
                            <div class="mb-2">
                                You have selected the <span class="fw-bold">1 Year Commitment</span> plan, 
                                which corresponds to a recurring monthly charge of <span class="fw-bold">{{ newCurrency }} {{ newAmount }}</span> for a duration of 12 months.
                            </div>
                            <ul>
                                <li>Requires 12 months contract, subject to closeout charge for early termination.</li>
                                <li>Pay each month based on total users committed upfront, starting {{func.convDateTimeFormat(new Date(), 'datetext')}}</li>
                                <li>Add users as needed, remove users only when you renew the contract</li>
                                <li>On completion of your contract period, your subscription will renew as per your renewal settings</li>
                            </ul>

                        </div>
                        <div v-if="payOpt === 'monthly'" class="col-12 mb-1">You have selected the <span class="fw-bold">No Commitment</span> plan.</div>
                        <div class="col-12 mb-3">Please review the details of your plan below and specify the number of user.</div>


                        <div class="col-12">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Plan</td>
                                        <td><input type="text" class="form-control" disabled="true" v-model="newPlan" id="plan" placeholder="Plan"></td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">Commitment</td>
                                        <td><input type="text" class="form-control" disabled="true" v-model="newCommitment" id="commitment" placeholder="Commitment"></td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">Amount / user</td>
                                        <td>
                                            <div class="input-group">
                                                <span class="input-group-text">{{ newCurrency }}</span>
                                                <input type="text" class="form-control" disabled="true" v-model="newAmount" id="amt" :placeholder="payOpt === 'yearly' ? 'Amount per month / user' : 'Amount / user'">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-top pt-3">Number of user</td>
                                        <td>
                                            <div class="input-group mb-3" style="width: 50%">
                                                <button class="btn btn-info text-white" type="button" @click="updNoUser('-')">-</button>
                                                <input type="text" class="form-control" v-model="newNoUser" ref="user" id="user" placeholder="No of user" autofocus>
                                                <button class="btn btn-info text-white" type="button" @click="updNoUser('+')">+</button>
                                            </div>
                                            <div v-if="isExceedPlanUserLimit === true" class="text-danger small">
                                                <div>The user limit has been exceeded.</div>
                                                <div>This plan supports a maximum of {{ newUserLimit }} users.</div>
                                                <div v-if="newPlan === 'STARTER'">To add more users, upgrade to <b>Standard</b> plan or contact us.</div>
                                                <div v-if="newPlan === 'STANDARD'">To add more users, contact us.</div>
                                            </div>
                                            <div v-if="totalUser > newNoUser" class="text-danger small">
                                                <div>A minimum of {{ totalUser }} licenses is required to accommodate your current users.</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="pt-0 pb-1"><hr class="my-0" /></td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold">Subtotal</td>
                                        <td class="fw-bold">{{ newCurrency }} {{ (newAmount*newNoUser).toFixed(1).toLocaleString() }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button class="btn btn-secondary me-2" :disabled="isExceedPlanUserLimit === true || totalUser > newNoUser" @click="checkoutPayment" data-bs-dismiss="modal">
                        <i class="fa-solid fa-cart-shopping fa-lg me-2"></i>Checkout
                    </button>
                    <!-- <button class="btn btn-secondary me-2" :disabled="isExceedPlanUserLimit === true" @click="checkoutPayment2" data-bs-dismiss="modal">
                        <i class="fa-solid fa-cart-shopping fa-lg me-2"></i>Checkout2
                    </button> -->
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- Modal End-->
</template>

<script>

import { ref, inject, watch, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
// import Popper from 'vue3-popper'
// conflic with preference drop down menu
// import { Modal } from 'bootstrap'

export default {
    name: 'Plan',
    components: { Alert, TopNavigation/* , Popper */ },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getPackage, getUserId, getDisplayName } = useStore()

        const isLogin = ref(false)
        const isFinance = ref(false)
        const mdlPreSubscription = ref(null)
        const totalUser = ref(0)

        const payOpt = ref('yearly')    // monthly/yearly
        const user = ref(null)
        const showMore = ref(false)

        // choosen plan for checkout purpose
        const newPlan = ref(null)
        const newCommitment = ref(null)
        const newCurrency = ref(null)
        const newAmount = ref(null)
        const newNoUser = ref(1)
        const newUserLimit = ref(0)

        const reqName = ref(null)
        const reqEmail = ref(null)
        const reqRemark = ref(null)

        const isExceedPlanUserLimit = ref(false)

        const arrPlan = ref([])

        watch([newNoUser], () => {
            if (newNoUser.value > newUserLimit.value ) {
                isExceedPlanUserLimit.value = true

            } else {
                isExceedPlanUserLimit.value = false
                if (newNoUser.value <= 0) {
                    newNoUser.value = 1
                }
            }
        })

        watch([payOpt], () => {
            getPlanList()
        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const updNoUser = async (opt) => {

            isExceedPlanUserLimit.value = false
            newNoUser.value = newNoUser.value * 1

            // console.info('updNoUser', opt, newNoUser.value, typeof newNoUser.value, '|', arrPlan.value[newPlan.value]['user'], typeof arrPlan.value[newPlan.value]['user'])
            if (opt === '-') {
                if (newNoUser.value > 1) {
                    newNoUser.value = newNoUser.value - 1
                }

            } else {
                if (newNoUser.value <= newUserLimit.value) {
                    newNoUser.value = newNoUser.value + 1

                } else {
                   isExceedPlanUserLimit.value = true
                }
            }
        }

        const preCheckout = async (plan, cur, amt, userLimit) => {

            newPlan.value = plan
            newCommitment.value = payOpt.value === 'yearly' ? '1 Year' : 'No'
            newCurrency.value = cur
            newAmount.value = (amt*1).toFixed(1)
            newNoUser.value = totalUser.value === 0 ? 1 : totalUser.value
            newUserLimit.value = userLimit

            // console.info('preCheckout',  newNoUser.value, newUserLimit.value, 'userLimit:', userLimit)

            if (newNoUser.value <= newUserLimit.value) {
                isExceedPlanUserLimit.value = false

            } else {
                isExceedPlanUserLimit.value = true
            }
        }

        const checkoutPayment = async () => {

            /* const paymentUrl = axios.defaults.baseURL + 'payment/stripe/recurring/checkout/' + newCurrency.value + '/' + newAmount.value * newNoUser.value + '/' + newPlan.value + '/' + 'payment'
            console.info('checkoutPayment: paymentUrl', paymentUrl)
            window.location.href = paymentUrl */

            console.info('newCommitment', newCommitment.value)
             try {
                const p = {
                    currency: newCurrency.value,
                    amount: newAmount.value * newNoUser.value,
                    plan: newPlan.value, 
                    subscription: payOpt.value === 'yearly' ? 'ANNUM' : 'MONTHLY',
                    totalLicense: newNoUser.value,
                    paymentUri: '/payment',
                    successUri: '/payment/success',
                    failedUri: '/payment/failed'

                }
                console.info('checkoutPayment p', JSON.stringify(p))
                const res = await axios.put('/plan/change', p)
                console.info('checkoutPayment res', JSON.stringify(res.data.data))

                if( res.data.status === 1) {
                    console.info('checkoutPayment invoiceId', res.data.data.invoiceId)

                    const paymentUrl = axios.defaults.baseURL + 'payment/stripe/recurring/checkout/' + res.data.data.invoiceId
                    console.info('checkoutPayment: paymentUrl', paymentUrl)
                    window.location.href = paymentUrl 
                   
                } else {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.getError(res.data)
                    })
                    
                }

            } catch (error) {
                func.addLog('plan', 'checkoutPayment - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                // console.info('plan err', error)
            }
        }

        const checkoutPayment2 = async () => {

            const paymentUrl = axios.defaults.baseURL + 'payment/stripe/recurring/checkout/' + newCurrency.value + '/' + newAmount.value * newNoUser.value + '/' + newPlan.value + '/' + '/payment'
            console.info('checkoutPayment2: paymentUrl', paymentUrl)
            // window.location.href = paymentUrl
        }

        const DUMP_getPlan = (plan) => {
            newPlan.value = plan
            console.info('getPlan', plan)

            if (isLogin.value === true) {
                // go payment gateway
                router.push({ path: '/auth/preference/payment3', query: {plan: plan} })
            } else {
                // conflict with preference 
                mdlPreSubscription.value = new Modal(document.getElementById('mdlPreSubscription'))
                mdlPreSubscription.value.show()
            }
            
        }

        const getTrial = (plan) => {
            console.info('getTrial', plan)
        }

        const chkSession = async () => {
            isLogin.value = false
            
            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {
                    // console.info('res', JSON.stringify(res.data.data))
                    isLogin.value = true
                    isFinance.value = res.data.data.jEntity.isFinance
                   
                } else {
                    isLogin.value = false
                    
                }

                if (isLogin.value === true && getUserId.value !== null && getUserId.value !== undefined) {
                    reqEmail.value = getUserId.value
                }

                if (isLogin.value === true && getDisplayName.value !== null && getDisplayName.value !== undefined) {
                    reqName.value = getDisplayName.value
                }

            } catch (error) {
                isLogin.value = false
                func.addLog('plan', 'chkSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                // console.info('plan err', error)
            }
        }

        const getPlanList = async () => {
            const p = {
                currency: 'USD',
                subscription: payOpt.value === 'yearly' ? 'ANNUM' : 'MONTHLY'
            }

            axios.post('/plan/list', p)
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        // console.info('getPlanList arrPlan', JSON.stringify(res.data.data))
                        const arrPlanTmp = res.data.data
                        arrPlan.value = arrPlanTmp.sort((a, b) => a.chargePerUser - b.chargePerUser)

                    } else {
                        func.addLog('plan', 'getPlanList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('plan', 'getPlanList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getTotalUsers = () => {
            const p = {
            }

            axios.post('/entity/member/list', p)
                .then((res) => {
                    
                    if (res.data.status === 1) {
                        totalUser.value = res.data.description.rowCount
                       
                    } else {
                        func.addLog('plan', 'getTotalUsers', res)
                    }

                })
                .catch((error) => {
                    func.addLog('plan', 'getTotalUsers - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        onMounted(() => {
            chkSession()
            getPlanList()
            getTotalUsers()

            let objMdl1 = document.querySelector('#mdlConfirmSubscription')
            objMdl1.addEventListener("hidden.bs.modal", function (event) {
                // reset value when modal close
                newNoUser.value = 1
                isExceedPlanUserLimit.value = false

            })
        })

        return {
            axios, func, route, router, store, alert, closeAlert, isLogin, isFinance, getPackage, getTrial, newPlan, newCommitment, newCurrency, newAmount, newNoUser,
            newUserLimit, reqName, reqEmail, reqRemark, getDisplayName, checkoutPayment, checkoutPayment2, preCheckout, payOpt, arrPlan, user, updNoUser, isExceedPlanUserLimit,
            getPlanList, showMore, totalUser
        }
    }
}
</script>


<style>
    .planBox {
        border-radius: 10px;
        /* padding: 0px 22px 25px 22px; */
        padding: 0px;
        margin-bottom: 25px;
        margin-left: 2px;
        margin-right: 2px;
        color: #6E777E;
    }

    .planBoxReco {
        border-radius: 10px;
        /* padding: 25px 22px; */
        padding: 0px;
        margin-bottom: 25px;
        margin-left: 2px;
        margin-right: 2px;
        color: #6E777E;
        border: 1px solid var(--bs-primary);
    }

    .planHeader {
        background-color: #f8f9fa;
        padding: 5px 25px;
        margin-bottom: 15px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        text-align: center;
    }

    .planHeaderReco {
        background-color: #53BDEB;
        padding: 5px 25px;
        margin-bottom: 15px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        text-align: center;
    }

    .boxButton {
        /* margin-top: 50px; */
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
        /* margin-bottom: 0px; */
        /* padding-bottom: 0px; */
        height: 70px;
    }

    .boxContent {
        margin-left: 20px;
        margin-right: 20px;
    }

    .boxTitle {
        font-size: 50px;
        font-weight: bold;
        font-family: "TT-Firs-Neue-Demi-Bold", sans-serif;
        line-height: 74px;
        letter-spacing: -0.02em;
        color: #6E777E;
    }

    .iconBlue {
        color: #53BDEB;
    }

</style>